import * as React from "react"
import { SEO } from "../components/seo"
import Layout from '../components/layout'

// Icons
import { FaLinkedin } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'
import { FaHome } from 'react-icons/fa'

const ContactPage = () => {
  return (
	<Layout>
		<main>
			<section className="contact">
                <form id="fs-frm" name="simple-contact-form" accept-charset="utf-8" action="https://formspree.io/f/xnqedbyr" method="post">
                    <fieldset id="fs-frm-inputs">
                        <label for="full-name">Full Name</label>
                        <input type="text" name="name" id="full-name" placeholder="First and Last" required=""></input>
                        <label for="email-address">Email Address</label>
                        <input type="email" name="_replyto" id="email-address" placeholder="email@example.com" required=""></input>
                        <label for="message">Message</label>
                        <textarea rows="5" name="message" id="message" placeholder="Help! I need a web developer!" required=""></textarea>
                        <input type="hidden" name="_subject" id="email-subject" value="Contact Form Submission"></input>
                    </fieldset>
                    <input type="submit" className="button" value="Submit"></input>
                </form>
            </section>
		</main>
	</Layout>
  )
}
export default ContactPage

export const Head = () => (
	<SEO />
)
